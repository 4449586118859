import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'components/core';
import { Typography, Box, Divider } from '@mui/material';
import { useYupObject } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { Formik } from 'formik';
import { RecordingType, Channel, ChannelInitialValues, ProtocolType } from 'types/channels.types';
import { ChannelForm } from 'components/Channel';
import { HowToCustomURLSingleModal } from 'components/Channel/ChannelForm';

interface Props {
  initialValues: ChannelInitialValues | Channel;
  doSubmit: (channel: Channel) => Promise<Channel | void>;
  showDeleteButton?: boolean;
  doDelete?: (id: number) => Promise<void>;
  onClose: () => void;
  customerId?: number;
}

export function RTSPChannelForm({
  initialValues,
  doSubmit,
  showDeleteButton,
  doDelete,
  onClose,
  customerId,
}: Props) {
  const { t } = useTranslation('channels');
  const yup = useYupObject();
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const [helpOpen, setHelpOpen] = React.useState(false);

  const validationSchema = yup.object({
    status: yup.bool(),
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    user: yup.string().trim().max(100).nullable(),
    password: yup.string().trim().max(100).nullable(),
    specificUrl: yup.bool(),
    urlToFormat: yup.string().trim().max(500).required(),
    urlOnlyRecordToFormat: yup.string().trim().max(500).nullable().when('specificUrl', {
      is: true,
      then: yup.string().required(),
    }),
    url: yup.string().rtsp().trim().max(500).required(),
    urlOnlyRecord: yup.string().rtsp().trim().max(500).nullable().when('specificUrl', {
      is: true,
      then: yup.string().required(),
    }),
    channelGroup: yup
      .object({
        id: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    retentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().max(9999).min(1).integer().required(),
      otherwise: yup.number(),
    }),
    channelPrivate: yup.bool(),
    activeTransmissionCreditLimit: yup.bool(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    activeStorageLimit: yup.bool(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    simpleScheduledRecording: yup
      .object({
        id: yup.number(),
        name: yup.string(),
      })
      .nullable(),
    enablePreAlarm: yup.bool(),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        channelGroup: initialValues.channelGroup && {
          id: initialValues.channelGroup.id,
          label: initialValues.channelGroup.name,
        },
      }}
      onSubmit={async (valuesReceived, { setSubmitting }) => {
        const isScheduleLinked = Boolean(
          initialValues.recordingType === RecordingType.SCHEDULE &&
            initialValues.simpleScheduledRecording?.id
        );

        const hasUnlinkedSchedule =
          isScheduleLinked && valuesReceived.recordingType !== RecordingType.SCHEDULE;

        setSubmitting(true);

        try {
          const transformedValues = {
            ...valuesReceived,
            channelGroup: valuesReceived.channelGroup
              ? {
                  id: valuesReceived.channelGroup.id,
                  name: valuesReceived.channelGroup.label,
                }
              : undefined,
            connectionTypeEnum: ProtocolType.RTSP,
            simpleScheduledRecording: hasUnlinkedSchedule
              ? null
              : valuesReceived.simpleScheduledRecording,
          };

          await doSubmit(transformedValues as Channel);
          setSubmitting(false);
          onClose();
        } catch (error) {
          console.error(error);
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <form
          aria-label="channel"
          onSubmit={formik.handleSubmit}
          style={{
            overflow: 'hidden',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'auto',
              py: 1.5,
              pr: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0.6rem',
                fontSize: '16px',
              }}
            >
              {t('channels:information')}
            </Typography>

            <ChannelForm.StatusInput />
            <Box>
              <ChannelForm.NameInput />
            </Box>
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.UsernameInput
                helperText={t('channels:in_the_url_field_replace_the_user_with_username')}
              />
              <ChannelForm.PasswordInput
                helperText={t('channels:in_the_url_field_replace_the_password_with_password')}
              />
            </Box>
            <ChannelForm.SpecificUrlCheckbox />
            {formik.values.specificUrl ? (
              <Box sx={{ marginBottom: 4, display: 'flex', width: '100%', gap: 2 }}>
                <Box sx={{ width: '100%' }}>
                  <ChannelForm.RTSPUrlToFormatInput />
                  <ChannelForm.TestChannelButton channelType="live" />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <ChannelForm.RTSPUrlOnlyRecordToFormatInput />
                  <ChannelForm.TestChannelButton channelType="record" />
                </Box>
              </Box>
            ) : (
              <Box sx={{ marginBottom: 4 }}>
                <ChannelForm.RTSPUrlInput />
                <ChannelForm.TestChannelButton channelType="live" />
              </Box>
            )}
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.ChannelGroupSelect />
              <ChannelForm.RecordingTypeSelect />
              <ChannelForm.RetentionTimeInput />
            </Box>
            <Box>
              <ChannelForm.EnablePreAlarmInput />
            </Box>
            <Box display="flex">
              <ChannelForm.CreditLimitCheckBox />
              <ChannelForm.StorageLimitCheckBox />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                mb: 4,
              }}
            >
              <ChannelForm.CreditLimitInput />
              <ChannelForm.StorageLimitInput />
            </Box>
            <ChannelForm.PrivateChannel />
            <ChannelForm.RecordingScheduleSelect />
          </Box>
          <Divider />
          <Box
            sx={{
              flex: '0 0 auto',
              padding: '20px',
              textAlign: 'end',
            }}
          >
            {showDeleteButton && excludePermission && (
              <Form.DeleteButton
                isSubmitting={formik.isSubmitting}
                confirmModalDescription={t(
                  'channels:not_possible_retrieve_information_and_view_image_camera'
                )}
                confirmModalTitle={t('channels:want_delete')}
                onConfirmDelete={async (setIsDeleting) => {
                  try {
                    if (!doDelete) return;
                    if (!initialValues.id) return;
                    await doDelete(initialValues.id);
                    setIsDeleting(false);
                    onClose && onClose();
                  } catch (error) {
                    console.error(error);
                    setIsDeleting(false);
                  }
                }}
              />
            )}
            <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={onClose} />
            <ChannelForm.SaveButton
              isSubmitting={formik.isSubmitting}
              disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
              onClick={formik.handleSubmit}
            />
          </Box>
          <HowToCustomURLSingleModal open={helpOpen} setOpen={() => setHelpOpen(false)} />
        </form>
      )}
    </Formik>
  );
}
