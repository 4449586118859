import React from 'react';
import { Box, Typography, Divider, IconButton, Tooltip, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useYupObject, useQuery, useFetch } from 'hooks';
import { Formik } from 'formik';
import { Form, isSaveButtonDisabled, useFormStyles } from 'components/core';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { useHavePermission } from 'hooks/useHavePermission';
import { PermissionRole } from 'types/user.types';
import InfoIcon from '@mui/icons-material/Info';
import { PartnerType, RecordingType } from 'types/channels.types';
import SelectManuFacturer from 'components/SelectManufacturer';
import { ChannelForm } from 'components/Channel';
import { HikvisionSettingsProps } from 'pages/HikvisionSettingsPage/useHikvisionSettings';
import { HikVisionChannel } from 'types/hikvision.types';
import ChannelTestButton from '../../ChannelTestButton/ChannelTestButton';

export function HikvisionForm({
  customerId,
  initialValues,
  doSubmit,
  doDelete,
  showDeleteButton,
  onClose,
}: {
  customerId: string;
  initialValues: HikVisionChannel;
  doSubmit: (camera: HikVisionChannel) => Promise<void>;
  showDeleteButton?: boolean;
  doDelete?: (id: number) => Promise<void>;
  onClose: () => void;
}) {
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const classesForm = useFormStyles({});
  const { t } = useTranslation(['channels', '_common', 'forms']);
  const yup = useYupObject();

  const history = useHistory();
  const query = useQuery();
  const validationSchema = yup.object({
    name: yup.string().trim().max(100).required().min(3).noSpecialCharacters(),
    p2pChannel: yup.number().required().max(999999).min(0),
    serialNumber: yup
      .string()
      .required()
      .max(255)
      .min(9, t('forms:number_min', { min: 9 }))
      .trim(),
    privateKey: yup.string().trim().max(255).required(),
    p2pPartner: yup.string().trim().required(),
    channelGroup: yup
      .object({
        id: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    retentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().max(9999).min(1).integer().required(),
      otherwise: yup.number(),
    }),
    channelPrivate: yup.bool(),
    activeTransmissionCreditLimit: yup.boolean(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    activeStorageLimit: yup.bool(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    simpleScheduledRecording: yup
      .object({
        id: yup.number(),
        name: yup.string(),
      })
      .nullable(),
    subtypeStream: yup.boolean(),
  });

  const { data: fetchSettings } = useFetch<HikvisionSettingsProps>(
    '/v1/masterCompanies/settings/hikvision'
  );

  const handleManuFacturerChange = (event: SelectChangeEvent<PartnerType>) => {
    event.target.value === PartnerType.INTELBRAS &&
      history.push(routes.customer.channel.create.registerIntelbras(customerId));
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        channelGroup: initialValues.channelGroup && {
          id: initialValues.channelGroup.id,
          label: initialValues.channelGroup.name,
        },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const isScheduleLinked = Boolean(
          initialValues.recordingType === RecordingType.SCHEDULE &&
            initialValues.simpleScheduledRecording?.id
        );

        const hasUnlinkedSchedule =
          isScheduleLinked && values.recordingType !== RecordingType.SCHEDULE;

        try {
          const validatedValues = {
            ...values,
            channelGroup: values.channelGroup
              ? {
                  id: values.channelGroup.id,
                  name: values.channelGroup.label,
                }
              : undefined,
            simpleScheduledRecording: hasUnlinkedSchedule ? null : values.simpleScheduledRecording,
          };
          await doSubmit(validatedValues);
          setSubmitting(false);
          onClose();
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <form
          aria-label="hikvisionForm"
          className={classesForm.form}
          onSubmit={formik.handleSubmit}
        >
          {!initialValues.id && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1.8rem',
                marginLeft: '2rem',
                marginTop: '1rem',
              }}
            >
              <SelectManuFacturer
                value={formik.values.p2pPartner}
                onChange={handleManuFacturerChange}
              />
            </Box>
          )}
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'auto',
              px: 4,
              py: 1.5,
              marginBottom: '1rem',
            }}
          >
            <Typography variant="subtitle2" className={classesForm.formInputsGroupLabel}>
              {t('channels:warning_in_hikvision_page')}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0.6rem',
                fontSize: '16px',
              }}
            >
              {t('channels:information')}
            </Typography>
            <ChannelForm.StatusInput />
            <ChannelForm.NameInput />

            <Box
              sx={{
                marginBottom: '1.2rem',
                display: 'flex',
                gap: 2,
              }}
              display="flex"
            >
              <ChannelForm.SerialNumberInput />
              <ChannelForm.P2PChannelInput />
              <ChannelForm.PrivateKeyInput />
            </Box>
            <ChannelForm.ExtraStreamCheckBox />
            <Box display="inline-flex" width="100%" mb={4}>
              <Box display="inline-flex" width="55%" alignItems="center">
                <ChannelTestButton.Hikvision
                  data={{
                    channel: formik.values.p2pChannel,
                    serial: formik.values.serialNumber,
                    key: formik.values.privateKey,
                    name: formik.values.name,
                  }}
                  isDisabled={
                    (!!formik.touched.serialNumber && Boolean(formik.errors.serialNumber)) ||
                    (!!formik.touched.privateKey && Boolean(formik.errors.privateKey)) ||
                    Boolean(!formik.values.serialNumber || !formik.values.privateKey)
                  }
                  batchKey=""
                />
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.ChannelGroupSelect />
              <ChannelForm.RecordingTypeSelect />
              <ChannelForm.RetentionTimeInput />
            </Box>
            <Box>
              <ChannelForm.EnablePreAlarmInput />
            </Box>
            <Box display="flex">
              <ChannelForm.CreditLimitCheckBox />
              <ChannelForm.StorageLimitCheckBox />
            </Box>
            <Box display="flex" width="100%" marginBottom={4}>
              <ChannelForm.CreditLimitInput />
              <ChannelForm.StorageLimitInput />
            </Box>
            <ChannelForm.PrivateChannel />
            <ChannelForm.RecordingScheduleSelect />
          </Box>
          <Divider />
          <Box
            sx={{
              flex: '0 0 auto',
              padding: '20px',
              display: 'flex',
              justifyContent: showDeleteButton && excludePermission ? 'space-between' : 'flex-end',
              alignItems: 'center ',
            }}
          >
            {showDeleteButton && excludePermission && (
              <Form.DeleteButton
                isSubmitting={formik.isSubmitting}
                confirmModalDescription={t(
                  'not_possible_retrieve_information_and_view_image_camera'
                )}
                confirmModalTitle={t('want_delete')}
                onConfirmDelete={async (setIsDeleting) => {
                  try {
                    if (!doDelete) return;
                    if (!initialValues.id) return;
                    await doDelete(initialValues.id);
                    setIsDeleting(false);
                    history.push(query.get('returnRoute') || routes.customer.root());
                  } catch (error) {
                    console.error(error);
                    setIsDeleting(false);
                  }
                }}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center ',
              }}
            >
              {!fetchSettings?.appKey?.length && !fetchSettings?.secretKey?.length && (
                <Typography
                  ml={4}
                  fontSize={14}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  p="0.2rem 1rem"
                  borderRadius={50}
                  color="error"
                >
                  <Tooltip title={t('hikvision_settings:config')}>
                    <IconButton onClick={() => history.push(routes.settings.hikvision)}>
                      <InfoIcon color="error" />
                    </IconButton>
                  </Tooltip>
                  {t('hikvision_settings:config_not_found')}
                </Typography>
              )}
              <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={onClose} />
              <ChannelForm.SaveButton
                disabled={
                  !formik.isValid ||
                  !formik.dirty ||
                  isSaveButtonDisabled({
                    initialValues: formik.initialValues,
                    isSubmitting: formik.isSubmitting,
                    values: formik.values,
                  })
                }
                isSubmitting={formik.isSubmitting}
                onClick={formik.handleSubmit}
              />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}
