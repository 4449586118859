import React from 'react';

declare global {
  interface Window {
    isWebView?: boolean;
  }
}

function useVMSDownloaded() {
  const [downloaded, setDownloaded] = React.useState(false);

  React.useEffect(() => {
    // It will be created in web view instance and will be true
    const isWebView = typeof window !== 'undefined' && window.isWebView;

    if (isWebView) {
      setDownloaded(true);
    }
  }, []);

  return downloaded;
}

export { useVMSDownloaded };
