import React from 'react';
import { Box, Typography, FormControl, Divider, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useYupObject, useQuery } from 'hooks';
import { Formik } from 'formik';
import { Form, useFormStyles } from 'components/core';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { useHavePermission } from 'hooks/useHavePermission';
import { PermissionRole } from 'types/user.types';
import { useSetRecoilState } from 'recoil';
import { batchIntelbrasFormAtom, intelbrasConnectionTestsResponse } from 'atoms/intelbras';
import { PartnerType, RecordingType } from 'types/channels.types';
import { unwantedChars } from 'hooks/useYupObject';
import { ChannelForm } from 'components/Channel';
import { IntelbrasChannel } from 'types/intelbras.types';
import ChannelTestButton from '../../ChannelTestButton/ChannelTestButton';

type IntelbrasPROPS = {
  customerId: string;
  initialValues: IntelbrasChannel;
  doSubmit: (camera: IntelbrasChannel) => Promise<void>;
  showDeleteButton?: boolean;
  doDelete?: (id: number) => Promise<void>;
};

export function IntelbrasBatchForm({
  customerId,
  initialValues,
  doSubmit,
  doDelete,
  showDeleteButton,
}: IntelbrasPROPS) {
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const classesForm = useFormStyles({});
  const { t } = useTranslation(['channels', '_common', 'forms']);
  const yup = useYupObject();
  const history = useHistory();
  const query = useQuery();
  const validationSchema = yup.object({
    qtyChannels: yup
      .number()
      .min(2)
      .max(999)
      .required()
      .test(
        'only_numbers',
        t('forms:only_numbers'),
        (value) => !String(value).match(unwantedChars)
      ),
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    serialNumber: yup
      .string()
      .required()
      .max(30)
      .min(9, t('forms:number_min', { min: 9 }))
      .trim(),
    // p2pChannel: yup.number().required().min(2).max(999),
    user: yup.string().trim().max(100).required(),
    password: yup.string().required().max(100),
    p2pPartner: yup.string().trim().required(),
    channelGroup: yup
      .object({
        id: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    retentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().max(9999).min(1).integer().required(),
      otherwise: yup.number(),
    }),
    channelPrivate: yup.bool(),
    activeTransmissionCreditLimit: yup.boolean(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    activeStorageLimit: yup.bool(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    simpleScheduledRecording: yup.object({
      id: yup.number(),
      name: yup.string(),
    }),
    port: yup
      .number()
      .required()
      .min(0, t('form:invalid_port_number'))
      .max(65535, t('form:invalid_port_number')),
  });
  const setBatchFormAtom = useSetRecoilState(batchIntelbrasFormAtom);
  const setBatchResponseAtom = useSetRecoilState(intelbrasConnectionTestsResponse);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        channelGroup: initialValues.channelGroup && {
          id: initialValues.channelGroup.id,
          label: initialValues.channelGroup.name,
        },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const isScheduleLinked = Boolean(
          initialValues.recordingType === RecordingType.SCHEDULE &&
            initialValues.simpleScheduledRecording?.id
        );

        const hasUnlinkedSchedule =
          isScheduleLinked && values.recordingType !== RecordingType.SCHEDULE;

        try {
          setBatchFormAtom(
            Array.from({ length: values.qtyChannels }).map((_, index) => {
              const data = {
                ...values,
                name: `${values.name} ${index + 1}`,
                p2pChannel: index + 1,
                uuid: `${index + 1}`,
                channelGroup: values.channelGroup
                  ? {
                      id: values.channelGroup.id,
                      name: values.channelGroup.label,
                    }
                  : undefined,
                simpleScheduledRecording: hasUnlinkedSchedule
                  ? null
                  : values.simpleScheduledRecording,
              };
              return data as IntelbrasChannel;
            })
          );
          setSubmitting(false);
          history.push(routes.customer.channel.create.previewBatchIntelbras(Number(customerId)));
        } catch (error) {
          console.error(error);
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <form
          aria-label="intelbrasForm"
          className={classesForm.form}
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'auto',
              px: 4,
              py: 1.5,
            }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <FormControl
                variant="outlined"
                sx={{
                  width: 300,
                }}
              >
                <Box>
                  <InputLabel
                    id="select-fabricator"
                    sx={{
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('channels:select_the_manufacturer')}
                  </InputLabel>

                  <Select
                    fullWidth
                    name="manuFacturerSelect"
                    data-testid="manuFacturerSelect"
                    label={t('channels:select_the_manufacturer')}
                    value="INTELBRAS"
                    onChange={({ target }) => {
                      if ((target.value as PartnerType) === PartnerType.HIKVISION) {
                        history.push(routes.customer.channel.create.formBatchHikvision(customerId));
                      }
                    }}
                    sx={{
                      textTransform: 'uppercase',
                    }}
                  >
                    <MenuItem value="INTELBRAS">INTELBRAS</MenuItem>
                    <MenuItem value="HIKVISION">HIKVISION</MenuItem>
                  </Select>
                </Box>
              </FormControl>
            </Box>

            <Typography variant="subtitle2" className={classesForm.formInputsGroupLabel}>
              {t('channels:warning_in_intelbras_page')}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0.6rem',
                fontSize: '16px',
              }}
            >
              {t('channels:information')}
            </Typography>
            <ChannelForm.StatusInput />

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                mb: 2,
              }}
            >
              <ChannelForm.QtyChannelsInput />
              <ChannelForm.PortInput />
            </Box>
            <ChannelForm.NameInput />
            <Box
              sx={{
                marginBottom: '1.2rem',
                display: 'flex',
                gap: 2,
              }}
              display="flex"
            >
              <ChannelForm.SerialNumberInput />
              <ChannelForm.P2PChannelInput />
            </Box>
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.UsernameInput />
              <ChannelForm.PasswordInput />
            </Box>
            <ChannelForm.ExtraStreamCheckBox />
            <Box display="inline-flex" width="100%" mb={4}>
              <ChannelTestButton.BatchIntelbras
                isDisabled={
                  (!!formik.touched.serialNumber && Boolean(formik.errors.serialNumber)) ||
                  (!!formik.touched.user && Boolean(formik.errors.user)) ||
                  (!!formik.touched.password && Boolean(formik.errors.password)) ||
                  (!!formik.touched.p2pChannel && Boolean(formik.errors.p2pChannel)) ||
                  (!!formik.touched.port && Boolean(formik.errors.port)) ||
                  Boolean(
                    !formik.values.name ||
                      !formik.values.serialNumber ||
                      !formik.values.user ||
                      !formik.values.password ||
                      formik.values.qtyChannels <= 1 ||
                      formik.values.qtyChannels > 999
                  )
                }
                data={{
                  name: formik.values.name,
                  serial: formik.values.serialNumber,
                  channel: formik.values.qtyChannels,
                  user: formik.values.user,
                  password: formik.values.password,
                  port: formik.values.port || 554, // default RTSP port
                }}
              />
            </Box>
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.ChannelGroupSelect />
              <ChannelForm.RecordingTypeSelect />
              <ChannelForm.RetentionTimeInput />
            </Box>
            <Box>
              <ChannelForm.EnablePreAlarmInput />
            </Box>
            <Box display="flex">
              <ChannelForm.CreditLimitCheckBox />
              <ChannelForm.StorageLimitCheckBox />
            </Box>
            <Box display="flex" width="100%" marginBottom={4}>
              <ChannelForm.CreditLimitInput />
              <ChannelForm.StorageLimitInput />
            </Box>
            <ChannelForm.PrivateChannel />
            <ChannelForm.RecordingScheduleSelect />
          </Box>
          <Divider />
          <Box className={classesForm.formFooter}>
            {showDeleteButton && excludePermission && (
              <Form.DeleteButton
                isSubmitting={formik.isSubmitting}
                confirmModalDescription={t(
                  'not_possible_retrieve_information_and_view_image_camera'
                )}
                confirmModalTitle={t('want_delete')}
                onConfirmDelete={async (setIsDeleting) => {
                  try {
                    if (!doDelete) return;
                    if (!initialValues.id) return;
                    await doDelete(initialValues.id);
                    setIsDeleting(false);
                    history.push(query.get('returnRoute') || routes.customer.root());
                  } catch (error) {
                    console.error(error);
                    setIsDeleting(false);
                  }
                }}
              />
            )}
            <Form.CancelButton
              isSubmitting={formik.isSubmitting}
              onClick={() => {
                setBatchResponseAtom([]);
                setBatchFormAtom([]);
                history.push(
                  query.get('returnRoute') ||
                    routes.customer.channel.create.selectBatchP2P(customerId)
                );
              }}
            />
            <Form.SaveButton
              isSubmitting={formik.isSubmitting}
              initialValues={formik.initialValues}
              values={formik.values}
              disabled={!formik.isValid || !formik.dirty}
              label={t('_common:next')}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
}
