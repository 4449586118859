import React from 'react';
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFetch, useQuery, useYupObject } from 'hooks';
import { unwantedChars } from 'hooks/useYupObject';
import { PartnerType, RecordingType } from 'types/channels.types';
import { useHistory } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import routes from 'helpers/routes';
import { hikvisionBatchFormAtom } from 'atoms/hikvision';
import ChannelTestButton from 'components/forms/ChannelTestButton/ChannelTestButton';
import { Form } from 'components/core';
import { useSetRecoilState } from 'recoil';
import { HikvisionSettingsProps } from 'pages/HikvisionSettingsPage/useHikvisionSettings';
import { ChannelForm } from 'components/Channel';
import { HikVisionChannel } from 'types/hikvision.types';

interface Props {
  customerId: string;
}

interface ChannelDefaultValues extends HikVisionChannel {
  qtyChannels: number;
}

export default function HikvisionBatchForm({ customerId }: Props) {
  const { t } = useTranslation();
  const yup = useYupObject();
  const history = useHistory();
  const query = useQuery();
  const setHikvisionBatchForm = useSetRecoilState(hikvisionBatchFormAtom);
  const { data: fetchSettings } = useFetch<HikvisionSettingsProps>(
    '/v1/masterCompanies/settings/hikvision'
  );
  const validationSchema = yup.object({
    name: yup.string().trim().max(100).required().min(3),
    qtyChannels: yup
      .number()
      .min(2)
      .max(999)
      .required()
      .test(
        'only_numbers',
        t('forms:only_numbers'),
        (value) => !String(value).match(unwantedChars)
      ),
    serialNumber: yup
      .string()
      .required()
      .max(255)
      .min(9, t('forms:number_min', { min: 9 }))
      .trim(),
    privateKey: yup.string().trim().max(100).required(),
    p2pPartner: yup.string().trim().required(),
    channelGroup: yup
      .object({
        id: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    retentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().max(9999).min(1).integer().required(),
      otherwise: yup.number(),
    }),
    channelPrivate: yup.bool(),
    activeTransmissionCreditLimit: yup.boolean(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    activeStorageLimit: yup.bool(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    simpleScheduledRecording: yup.object({
      id: yup.number(),
      name: yup.string(),
    }),
    subtypeStream: yup.bool(),
  });
  const initialValues: ChannelDefaultValues = React.useMemo(
    () => ({
      name: '',
      status: true,
      qtyChannels: 2,
      p2pChannel: 1,
      p2pPartner: PartnerType.HIKVISION,
      serialNumber: '',
      retentionTime: 0,
      privateKey: '',
      recordingType: RecordingType.LIVE,
      channelPrivate: false,
      subtypeStream: false,
      user: '',
      password: '',
      url: '',
      urlToFormat: '',
      urlOnlyRecord: '',
      urlOnlyRecordToFormat: '',
      specificUrl: false,
      idMask: '',
      activeTransmissionCreditLimit: false,
      transmissionCreditLimit: 0,
      activeStorageLimit: false,
      storageLimit: 0,
      enablePreAlarm: false,
    }),
    []
  );

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Formik
        {...{
          initialValues: {
            ...initialValues,
            channelGroup: initialValues.channelGroup && {
              id: initialValues.channelGroup.id,
              label: initialValues.channelGroup.name,
            },
          },
          validationSchema,
          onSubmit: (values, { setSubmitting }) => {
            const isScheduleLinked = Boolean(
              initialValues.recordingType === RecordingType.SCHEDULE &&
                initialValues.simpleScheduledRecording?.id
            );

            const hasUnlinkedSchedule =
              isScheduleLinked && values.recordingType !== RecordingType.SCHEDULE;

            try {
              setHikvisionBatchForm(
                Array.from({ length: values.qtyChannels }, (_, i) => {
                  const channel = {
                    ...values,
                    name: `${values.name} ${i + 1}`,
                    serialNumber: values.serialNumber,
                    p2pChannel: i + 1,
                    channelGroup: values.channelGroup
                      ? {
                          id: values.channelGroup.id,
                          name: values.channelGroup.label,
                        }
                      : undefined,

                    simpleScheduledRecording: hasUnlinkedSchedule
                      ? null
                      : values.simpleScheduledRecording,
                  };
                  return channel as ChannelDefaultValues;
                })
              );
              setSubmitting(false);
              history.push(
                routes.customer.channel.create.previewBatchHikvision(Number(customerId))
              );
            } catch (error) {
              console.error(error);
              setSubmitting(false);
            }
          },
        }}
      >
        {(formik) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 1.5,
              overflow: 'hidden',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                flex: '1 1 auto',
                overflow: 'auto',
                px: 4,
                py: 1.5,
                marginBottom: '1rem',
              }}
            >
              <Box display="flex" alignItems="center" mb={2}>
                <FormControl
                  variant="outlined"
                  sx={{
                    width: 300,
                  }}
                >
                  <Box>
                    <InputLabel
                      id="select-fabricator"
                      sx={{
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('channels:select_the_manufacturer')}
                    </InputLabel>

                    <Select
                      fullWidth
                      name="manuFacturerSelect"
                      data-testid="manuFacturerSelect"
                      label={t('channels:select_the_manufacturer')}
                      value="HIKVISION"
                      onChange={({ target }) => {
                        if ((target.value as PartnerType) === PartnerType.INTELBRAS) {
                          history.push(
                            routes.customer.channel.create.formBatchIntelbras(customerId)
                          );
                        }
                      }}
                      sx={{
                        textTransform: 'uppercase',
                      }}
                    >
                      <MenuItem value="INTELBRAS">INTELBRAS</MenuItem>
                      <MenuItem value="HIKVISION">HIKVISION</MenuItem>
                    </Select>
                  </Box>
                </FormControl>
              </Box>

              <Typography variant="subtitle2">{t('channels:warning_in_hikvision_page')}</Typography>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: '0.6rem',
                  fontSize: '16px',
                }}
              >
                {t('channels:information')}
              </Typography>
              <Box
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <ChannelForm.StatusInput />
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <ChannelForm.QtyChannelsInput />
                  <ChannelForm.NameInput />
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: '1.2rem',
                  display: 'flex',
                  gap: 2,
                }}
                display="flex"
              >
                <ChannelForm.SerialNumberInput />
                <ChannelForm.P2PChannelInput />
                <ChannelForm.PrivateKeyInput />
              </Box>

              <ChannelForm.ExtraStreamCheckBox />

              <Box
                sx={{
                  marginBottom: '1.2rem',
                  display: 'flex',
                  gap: 2,
                }}
              >
                <ChannelTestButton.BatchHikvision
                  disabled={
                    formik.isSubmitting ||
                    !!formik.errors.name?.length ||
                    !!formik.errors.serialNumber?.length ||
                    !!formik.errors.privateKey?.length
                  }
                  {...{
                    name: formik.values.name,
                    serialNumber: formik.values.serialNumber,
                    qtyChannels: formik.values.qtyChannels,
                    privateKey: formik.values.privateKey,
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginBottom: '1.2rem',
                }}
                display="flex"
              >
                <ChannelForm.ChannelGroupSelect />
                <ChannelForm.RecordingTypeSelect />
                <ChannelForm.RetentionTimeInput />
              </Box>
              <Box>
                <ChannelForm.EnablePreAlarmInput />
              </Box>
              <Box display="flex">
                <ChannelForm.CreditLimitCheckBox />
                <ChannelForm.StorageLimitCheckBox />
              </Box>
              <Box display="flex" width="100%" marginBottom={4}>
                <ChannelForm.CreditLimitInput />
                <ChannelForm.StorageLimitInput />
              </Box>
              <ChannelForm.PrivateChannel />
              <ChannelForm.RecordingScheduleSelect />
            </Box>
            <Divider />
            <Box
              sx={{
                flex: '0 0 auto',
                padding: '20px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center ',
              }}
            >
              {!fetchSettings?.appKey?.length && !fetchSettings?.secretKey?.length && (
                <Typography
                  ml={4}
                  fontSize={14}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  p="0.2rem 1rem"
                  borderRadius={50}
                  color="error"
                >
                  <Tooltip title={t('hikvision_settings:config')}>
                    <IconButton onClick={() => history.push(routes.settings.hikvision)}>
                      <InfoIcon color="error" />
                    </IconButton>
                  </Tooltip>
                  {t('hikvision_settings:config_not_found')}
                </Typography>
              )}
              <Form.CancelButton
                isSubmitting={formik.isSubmitting}
                onClick={() =>
                  history.push(
                    query.get('returnRoute') ||
                      routes.customer.channel.create.selectBatchP2P(customerId)
                  )
                }
              />
              <Form.SaveButton
                isSubmitting={formik.isSubmitting}
                initialValues={formik.initialValues}
                values={formik.values}
                disabled={
                  (!fetchSettings?.appKey && !fetchSettings?.secretKey) ||
                  !formik.isValid ||
                  !formik.dirty
                }
                label={t('_common:next')}
                onClick={() => formik.handleSubmit()}
              />
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
