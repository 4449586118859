import React, { useCallback, useMemo, useState } from 'react';
import { RecordingType } from 'types/channels.types';
import { YAxis, XAxis, ResponsiveContainer, Legend, BarChart, Bar } from 'recharts';
import { Box, Divider, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { timeFormat } from 'd3';
import { Formatter } from 'recharts/types/component/DefaultLegendContent';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import millisecondsToHHMMSS from 'helpers/millisecondsToHHMMSS';
import colorByRecordingType from 'helpers/colorByRecordingType';
import { formatBytes } from 'helpers/bytesOperations';
// import { useDecision } from '@optimizely/react-sdk';
// import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { ConsuptionDetail } from './metrics';

interface ChartData {
  date: Date;
  recording_type_LIVE_duration: number;
  recording_type_EVENT_duration: number;
  recording_type_SCHEDULE_duration: number;
  recording_type_RECORD_24_7_duration: number;
  recording_type_LIVE_size: number;
  recording_type_EVENT_size: number;
  recording_type_SCHEDULE_size: number;
  recording_type_RECORD_24_7_size: number;
}

interface ListData {
  recordingType: RecordingType;
  totalChannels: number;
  totalMinutesRecording: number;
  totalSize: number;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: '48px',
  },
  listContentsSpacing: {
    margin: theme.spacing(0, 1),
  },
  listContentRecordingType: {
    margin: theme.spacing(0, 1),
    width: '350px',
  },
  listContentTotalChannels: {
    margin: theme.spacing(0, 1),
    width: '180px',
    textAlign: 'center',
    flex: 'inherit',
  },
  listContentTotalSize: {
    margin: theme.spacing(0, 1),
    width: '200px',
    flex: 'inherit',
  },
}));

function DetailRecordingCostsChart({ startDate, endDate }: { startDate: Date; endDate: Date }) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation(['channels', 'metrics', '_common']);
  const [opacity, setOpacity] = useState({
    recording_type_LIVE: 1,
    recording_type_EVENT: 1,
    recording_type_SCHEDULE: 1,
    recording_type_RECORD_24_7: 1,
    recording_type_PRE_ALARM: 1,
  });
  // const [preAlarmFlag] = useDecision(FEATURE_FLAGS_KEYS.PRE_ALARM_RECORDING_TYPE)

  const { data } = useFetch<ConsuptionDetail[]>('/v1/consumption/recording', {
    baseURL: process.env.REACT_APP_BASE_URL_CONSUMPTION,
    params: { startDate, endDate },
    normalizeData: (items) =>
      items.map((item) => ({
        ...item,
        date: new Date(`${String(item.date).replace('+00:00', '')}`),
        recordingDuration: item.recordingDuration * 1000,
      })),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const chartData = useMemo<ChartData[]>(() => {
    if (!data || data.length === 0) {
      return [];
    }
    // https://stackoverflow.com/a/9229821/9516375
    const uniqDates = [...new Set(data.map((item) => item.date.toDateString()))];
    return uniqDates
      .sort((a, b) => Number(new Date(a)) - Number(new Date(b)))
      .map((date) => ({
        date: new Date(date),
        recording_type_LIVE_duration: data
          .filter(
            (item) => item.date.toDateString() === date && item.recordingType === RecordingType.LIVE
          )
          .reduce((sum, item) => sum + item.recordingDuration, 0),
        recording_type_LIVE_size: data
          .filter(
            (item) => item.date.toDateString() === date && item.recordingType === RecordingType.LIVE
          )
          .reduce((sum, item) => sum + item.size, 0),
        recording_type_EVENT_duration: data
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.EVENT
          )
          .reduce((sum, item) => sum + item.recordingDuration, 0),
        recording_type_EVENT_size: data
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.EVENT
          )
          .reduce((sum, item) => sum + item.size, 0),
        recording_type_SCHEDULE_duration: data
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.SCHEDULE
          )
          .reduce((sum, item) => sum + item.recordingDuration, 0),
        recording_type_SCHEDULE_size: data
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.SCHEDULE
          )
          .reduce((sum, item) => sum + item.size, 0),
        recording_type_RECORD_24_7_duration: data
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.RECORD_24_7
          )
          .reduce((sum, item) => sum + item.recordingDuration, 0),
        recording_type_RECORD_24_7_size: data
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.RECORD_24_7
          )
          .reduce((sum, item) => sum + item.size, 0),
        // recording_type_PRE_ALARM_duration: data
        //   .filter(
        //     (item) =>
        //       item.date.toDateString() === date && item.recordingType === RecordingType.PRE_ALARM
        //   )
        //   .reduce((sum, item) => sum + item.recordingDuration, 0),
        // recording_type_PRE_ALARM_size: data
        //   .filter(
        //     (item) =>
        //       item.date.toDateString() === date && item.recordingType === RecordingType.PRE_ALARM
        //   )
        //   .reduce((sum, item) => sum + item.size, 0),
      }));
  }, [data]);

  const listDate = useMemo<ListData[]>(() => {
    if (!data || data.length === 0) {
      return [];
    }
    const uniqRecordingTypes = [...new Set(data.map((item) => item.recordingType))];
    return uniqRecordingTypes.map((recordingType) => ({
      recordingType,
      totalChannels: data.filter((item) => item.recordingType === recordingType).length,
      totalMinutesRecording: data
        .filter((item) => item.recordingType === recordingType)
        .reduce((totalSize, item) => totalSize + item.recordingDuration, 0),
      totalSize: data
        .filter((item) => item.recordingType === recordingType)
        .reduce((totalSize, item) => totalSize + item.size, 0),
    }));
  }, [data]);

  const handleMouseEnter = useCallback(
    ({ dataKey }) => {
      setOpacity({ ...opacity, [dataKey.replace('_duration', '').replace('_size', '')]: 0.5 });
    },
    [opacity]
  );

  const handleMouseLeave = useCallback(
    ({ dataKey }) => {
      setOpacity({ ...opacity, [dataKey.replace('_duration', '').replace('_size', '')]: 1 });
    },
    [opacity]
  );

  const renderLegendText: Formatter = useCallback(
    (value: string) => (
      <Typography display="inline" color="textSecondary">
        {t(value.replace('_duration', '').replace('_size', ''))}
      </Typography>
    ),
    [t]
  );

  if (chartData.length === 0) {
    return (
      <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h5">{t('_common:there_is_no_recording')}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Typography variant="body1">{t('metrics:time')}</Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={600}
          data={chartData}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          syncId="recording"
        >
          <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_LIVE_duration"
            strokeOpacity={opacity.recording_type_LIVE}
            stroke={colorByRecordingType(RecordingType.LIVE, theme)}
            fill={colorByRecordingType(RecordingType.LIVE, theme)}
            animationDuration={500}
          />
          <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_EVENT_duration"
            strokeOpacity={opacity.recording_type_EVENT}
            stroke={colorByRecordingType(RecordingType.EVENT, theme)}
            fill={colorByRecordingType(RecordingType.EVENT, theme)}
            animationDuration={500}
          />
          <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_SCHEDULE_duration"
            strokeOpacity={opacity.recording_type_SCHEDULE}
            stroke={colorByRecordingType(RecordingType.SCHEDULE, theme)}
            fill={colorByRecordingType(RecordingType.SCHEDULE, theme)}
            animationDuration={500}
          />
          <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_RECORD_24_7_duration"
            strokeOpacity={opacity.recording_type_RECORD_24_7}
            stroke={colorByRecordingType(RecordingType.RECORD_24_7, theme)}
            fill={colorByRecordingType(RecordingType.RECORD_24_7, theme)}
            animationDuration={500}
          />
          {/* {preAlarmFlag.enabled && <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_PRE_ALARM_duration"
            strokeOpacity={opacity.recording_type_PRE_ALARM}
            stroke={colorByRecordingType(RecordingType.PRE_ALARM, theme)}
            fill={colorByRecordingType(RecordingType.PRE_ALARM, theme)}
            animationDuration={500}
          />} */}
          <XAxis
            dataKey="date"
            tickFormatter={timeFormat('%d %b')}
            padding={{ left: 20, right: 20 }}
            tickMargin={10}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tickFormatter={(value: number) => millisecondsToHHMMSS(value)}
            padding={{ top: 10, bottom: 10 }}
            width={100}
            axisLine={false}
            tickLine={false}
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            verticalAlign="top"
            height={30}
            formatter={renderLegendText}
            wrapperStyle={{
              top: -20,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <Typography variant="body1">{t('metrics:data_transfer')}</Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={600}
          data={chartData}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          syncId="recording"
        >
          <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_LIVE_size"
            strokeOpacity={opacity.recording_type_LIVE}
            stroke={colorByRecordingType(RecordingType.LIVE, theme)}
            fill={colorByRecordingType(RecordingType.LIVE, theme)}
            animationDuration={500}
          />
          <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_EVENT_size"
            strokeOpacity={opacity.recording_type_EVENT}
            stroke={colorByRecordingType(RecordingType.EVENT, theme)}
            fill={colorByRecordingType(RecordingType.EVENT, theme)}
            animationDuration={500}
          />
          <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_SCHEDULE_size"
            strokeOpacity={opacity.recording_type_SCHEDULE}
            stroke={colorByRecordingType(RecordingType.SCHEDULE, theme)}
            fill={colorByRecordingType(RecordingType.SCHEDULE, theme)}
            animationDuration={500}
          />
          {/* {preAlarmFlag.enabled && <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_PRE_ALARM_duration"
            strokeOpacity={opacity.recording_type_PRE_ALARM}
            stroke={colorByRecordingType(RecordingType.PRE_ALARM, theme)}
            fill={colorByRecordingType(RecordingType.PRE_ALARM, theme)}
            animationDuration={500}
          />} */}
          <Bar
            type="monotone"
            barSize={17}
            dataKey="recording_type_RECORD_24_7_size"
            strokeOpacity={opacity.recording_type_RECORD_24_7}
            stroke={colorByRecordingType(RecordingType.RECORD_24_7, theme)}
            fill={colorByRecordingType(RecordingType.RECORD_24_7, theme)}
            animationDuration={500}
          />
          <XAxis
            dataKey="date"
            tickFormatter={timeFormat('%d %b')}
            padding={{ left: 20, right: 20 }}
            tickMargin={10}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tickFormatter={(value: number) => formatBytes(value)}
            padding={{ top: 10, bottom: 10 }}
            width={100}
            axisLine={false}
            tickLine={false}
            type="number"
          />
        </BarChart>
      </ResponsiveContainer>
      <List>
        {listDate.map((item) => (
          <ListItem key={item.recordingType} className={classes.listItem}>
            <Box
              bgcolor={colorByRecordingType(item.recordingType, theme)}
              width="5px"
              height="100%"
              marginRight="8px"
            />
            <Typography className={classes.listContentRecordingType} variant="button">
              {t(`recording_type_${item.recordingType}`)}
            </Typography>
            <Divider className={classes.listContentsSpacing} orientation="vertical" />
            <ListItemText className={classes.listContentTotalChannels}>
              {`${item.totalChannels} ${t('_common:channel', {
                count: Math.abs(item.totalChannels),
              }).toLowerCase()}`}
            </ListItemText>
            <Divider className={classes.listContentsSpacing} orientation="vertical" />
            <ListItemText className={classes.listContentTotalSize}>
              {millisecondsToHHMMSS(item.totalMinutesRecording)}
            </ListItemText>
            <Divider className={classes.listContentsSpacing} orientation="vertical" />
            <ListItemText className={classes.listContentTotalSize}>
              {formatBytes(item.totalSize)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default DetailRecordingCostsChart;
