import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, LinearProgressProps, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICurrentPlan } from 'pages/MetricsPage/storage';
import { formatBytes } from 'helpers/bytesOperations';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          sx={{
            height: '10px',
            borderRadius: '4px',
          }}
          color="inherit"
          variant="determinate"
          {...props}
        />
      </Box>
    </Box>
  );
}

function TitleCurrentPlan() {
  const [date, setDate] = useState('');

  const { t } = useTranslation();

  function getCurrentDate() {
    const today = new Date();
    setDate(
      new Intl.DateTimeFormat('pt-BR', {
        month: '2-digit',
        year: 'numeric',
      }).format(today)
    );
  }

  useEffect(() => {
    getCurrentDate();
  });
  return (
    <>
      <Typography fontSize={24} fontWeight="bold">
        {t('metrics:your_plan')}
      </Typography>
      <Typography fontSize={16}>
        (
        {t('metrics:current_month', {
          date,
        })}
        )
      </Typography>
    </>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.spacing(0, 1),
  flex: '1 1 50%',
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  maxWidth: '617px',
  alignItems: 'center',
  padding: '0 1.75rem',
  maxHeight: '155px',
}));

export default function CurrentPlan({ consumptionSize }: { consumptionSize: ICurrentPlan }) {
  const [percent, setPercent] = useState(0);
  const [free, setFree] = useState(0);
  const { t } = useTranslation();
  const { consumedSize, storageSize } = consumptionSize;

  useEffect(() => {
    setPercent((consumedSize / storageSize) * 100); // Calcule the porcent
    setFree(storageSize - consumedSize); // Calcule free storage
  }, [storageSize, consumedSize, percent, free]);

  return (
    <Container>
      <Box>
        <TitleCurrentPlan />
      </Box>
      <Box>
        <Typography mb={1} fontSize={16}>
          <Typography component="span" fontWeight="bold" fontSize={20}>
            {formatBytes(consumedSize)}
          </Typography>
          /{formatBytes(storageSize)} {t('metrics:used_comsumption')}
        </Typography>
        <Typography fontSize={16}>
          {percent ? percent.toFixed(2) : 0}% {t('metrics:used_comsumption')} -{' '}
          {formatBytes(free < 0 ? 0 : free)} {t('metrics:free_consumption')}
        </Typography>
        <LinearProgressWithLabel value={percent > 100 ? 100 : Number(percent.toFixed(2))} />
      </Box>
    </Container>
  );
}
