import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { sizeWrapper as sizeWrapperSelector } from 'atoms/leftpanel';
import SearchPanel from 'panels/SearchPanel';
import { useUpdateEffect, useKeyPressEvent, useLocation } from 'react-use';
import { SegwareLogo } from 'components/core/Icons';
import { Box, Divider, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorBoundary from 'components/ErrorBoundary';
import { useLeftPanel } from 'hooks/useLeftPanel';
import { useGlobalSearch } from 'hooks/useGlobalSearch';
import { timeAnimationMs } from 'types/leftpanel.types';
import { useSelectMosaicPanel } from 'hooks/mosaic/useSelectMosaicPanel';
import SelectChannelPanel from './SelectChannelPanel';
import MainMenu from './MainMenu';
import SelectCustomerPanel from './SelectCustomerPanel';
import SelectMosaicPanel from './SelectMosaicPanel';
import PlaylistDetailsPanel from './PlaylistDetailsPanel';

const useStylesRoot = makeStyles<Theme, { width?: string }>({
  wrapperContainer: {
    transition: `all ${timeAnimationMs}ms ease-in-out`,
    display: 'flex',
    width: ({ width }) => width,
  },
});

function LeftPanel() {
  const sizeWrapper = useRecoilValue(sizeWrapperSelector);
  const classes = useStylesRoot({ width: sizeWrapper });

  return (
    <Box className={classes.wrapperContainer}>
      <Content />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  segwareLogo: {
    height: '34px',
    width: '40px',
    color: theme.palette.text.primary,
  },
}));

function Content() {
  const {
    isMenuActual,
    isGlobalSearchActual,
    isSelectChannelActual,
    isSelectCustomerActual,
    isSelectMosaicActual,
    isPlaylistDetailsActual,
    actualPanelValue,
  } = useLeftPanel();
  const { toggleMosaicSelection } = useSelectMosaicPanel();

  const { toggleSearchPanel } = useGlobalSearch();
  const [animating, setAnimating] = useState(false);
  const classes = useStyles();

  const { pathname } = useLocation();
  const userIsOnMosaicPageExact = pathname === '/mosaics';

  useUpdateEffect(() => {
    setAnimating(true);
    setTimeout(() => {
      setAnimating(false);
    }, timeAnimationMs);

    return () => setAnimating(false);
  }, [actualPanelValue]);

  useKeyPressEvent(
    (e: KeyboardEvent) => (e.ctrlKey || e.metaKey) && e.key === '/',
    userIsOnMosaicPageExact ? toggleMosaicSelection : toggleSearchPanel
  );

  if (animating) {
    return (
      <Box padding="1rem 10px ">
        <SegwareLogo className={classes.segwareLogo} />
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      {isGlobalSearchActual() && <SearchPanel />}
      {isMenuActual() && <MainMenu />}
      {isSelectChannelActual() && <SelectChannelPanel />}
      {isSelectCustomerActual() && <SelectCustomerPanel />}
      {isSelectMosaicActual() && <SelectMosaicPanel />}
      {isPlaylistDetailsActual && <PlaylistDetailsPanel />}
      <Divider orientation="vertical" />
    </ErrorBoundary>
  );
}

export default LeftPanel;
