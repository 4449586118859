import React, { useState } from 'react';
import {
  TextFieldProps,
  InputAdornment,
  IconButton,
  CircularProgress,
  Theme,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDebounce, useUpdateEffect } from 'react-use';
import { Clear, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root.Mui-focused svg path': {
      fill: theme.palette.primary.main,
    },
  },
}));

function DebounceInput({
  onDebounce,
  timeDebounce = 600,
  setValue,
  ...props
}: TextFieldProps & {
  onDebounce: VoidFunction;
  setValue: (value: string) => void;
  timeDebounce?: number;
}) {
  const classes = useStyles();
  const [debouncing, setDebouncing] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { t } = useTranslation();

  useUpdateEffect(() => {
    // @ts-ignore
    props.value.length >= 3 && setDebouncing(true);
  }, [props.value]);

  useDebounce(
    () => {
      // @ts-ignore
      if (!props.value.length) {
        setHelperText('');
        setDebouncing(false);
        onDebounce();
        return;
      }

      // @ts-ignore
      if (props.value.length < 3) {
        setHelperText(
          t('forms:min_length', {
            min: 3,
          })
        );
        return;
      }

      setHelperText('');
      setDebouncing(false);
      onDebounce();
    },
    timeDebounce,
    [props.value]
  );

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <TextField
        className={classes.root}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search height="24px" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {debouncing && <CircularProgress size="1.2rem" />}
              {props.value && (
                <IconButton onClick={() => setValue('')} size="small">
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        {...props}
      />
      {!!helperText.length && (
        <Typography
          sx={{
            mt: 0.5,
            ml: 1,
          }}
          variant="body2"
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

export default DebounceInput;
