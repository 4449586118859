import React from 'react';
import {
  Box,
  Button,
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import { Link as RouteLink } from 'react-router-dom';
import { useFetch, useQuery } from 'hooks';
import { Recording, RecordingsGrouped, RecordingsGroupedApi } from 'types/recordings.types';
import { Column } from 'types/table.types';
import routes from 'helpers/routes';
import { timeFormat, timeSecond } from 'd3';
import millisecondsToHHMMSS from 'helpers/millisecondsToHHMMSS';
import Table from 'components/Table';
import exportAsPDF from 'services/exportAsPDF';
import { asyncComponent } from 'HOCs';

function useColumns({
  t,
  customerId,
}: {
  t: TFunction<Namespace<'channels' | '_common' | 'recordings'>>;
  customerId: number;
}): Column<Recording>[] {
  return React.useMemo(
    () => [
      {
        description: t('channels:name'),
        dataKey: 'channelName',
        linkTo: (row) => routes.customer.channel.view(customerId, row.id),
      },
      {
        description: t('_common:start_date'),
        dataKey: 'startDate',
        renderValue: (value) => (value instanceof Date ? timeFormat('%d %B %Y %H:%M')(value) : ''),
      },
      {
        description: t('_common:final_date'),
        dataKey: 'endDate',
        renderValue: (value) => (value instanceof Date ? timeFormat('%d %B %Y %H:%M')(value) : ''),
      },
      {
        description: t('recordings:duration'),
        dataKey: 'duration',
        renderValue: (value) => millisecondsToHHMMSS((value as number) * 1000),
      },
      {
        description: t('_common:resolution'),
        dataKey: 'resolution',
      },
      {
        description: t('_common:bitrate'),
        dataKey: 'bitrate',
        renderValue: (value) => `${value} kbps`,
      },
      {
        description: 'FPS',
        dataKey: 'fps',
      },
    ],
    [customerId, t]
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    overflow: 'auto',
  },
  container: {
    flex: '1 1 auto',
    marginBottom: theme.spacing(2),
  },
  tablePaginationContainer: {
    position: 'relative',
    minHeight: '52px',
  },
  tablePagination: {
    flex: '0 0 auto',
  },
  exportPDFButton: {
    position: 'absolute',
    '--halfHeight': 'calc(100% / 2)', // halfHeight do container pai
    top: 'calc(var(--halfHeight) - 18px)', // 18px; height button = 36px; 36px / 2 = 18px
    left: '8px',
  },
  tableCell: {
    backgroundColor: theme.palette.background.paper,
  },
  channelGroupLink: {
    marginLeft: theme.spacing(1),
  },
}));

function RecordingsTable({ customerId }: { customerId: number }) {
  const { t } = useTranslation();
  const columns = useColumns({ t, customerId });
  const classes = useStyles();
  const query = useQuery();

  const { data: recordingsGrouped } = useFetch<RecordingsGroupedApi[], RecordingsGrouped[]>(
    '/v1/recordings',
    {
      params: {
        clientId: customerId,
        channelsId: query.get('channels'),
        startDate: query.get('startDate'),
        endDate: query.get('endDate'),
      },
      normalizeData: (data: RecordingsGroupedApi[]) =>
        data.map((rGrouped) => ({
          ...rGrouped,
          recordings: rGrouped.recordings.map((recording) => ({
            ...recording,
            startDate: new Date(recording.startDate),
            endDate: new Date(recording.endDate),
          })),
        })),
    }
  );

  const recordings: Recording[] | undefined = React.useMemo(
    () =>
      recordingsGrouped
        ?.map((channel) =>
          channel.recordings.map((recordingChannel) => ({
            channelId: channel.id,
            channelName: channel.name,
            duration: timeSecond.count(recordingChannel.startDate, recordingChannel.endDate),
            ...recordingChannel,
          }))
        )
        .flat(),
    [recordingsGrouped]
  );

  const handleExportAsPDF = () => {
    recordings && exportAsPDF({ rows: recordings, t, title: t('recordings:recordings'), columns });
  };

  if (
    !recordings ||
    recordings?.length === 0 ||
    !recordingsGrouped ||
    recordingsGrouped?.length === 0
  ) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Typography color="textSecondary">{t('_common:no_results')}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box overflow="auto" flex="1 1 auto" marginX="24px">
        {recordingsGrouped
          .filter((rg) => rg.recordings.length > 0)
          .map((channel) => (
            <>
              <Link
                key={channel.id}
                component={RouteLink}
                to={routes.customer.channel.view(customerId, channel.id)}
                color="textPrimary"
                variant="body2"
              >
                <Typography className={classes.channelGroupLink} variant="h5">
                  {channel.name}
                </Typography>
              </Link>
              <TableContainer className={classes.container}>
                <TableMui aria-label={t('recordings:recordings')} size="medium">
                  <Table.Header
                    columns={columns.filter((column) => column.dataKey !== 'channelName')}
                  />
                  <TableBody aria-label="Corpo da tabela">
                    {recordings
                      .filter((recording) => recording.channelId === channel.id)
                      .map((row) => (
                        <TableRow key={row.id} aria-label={`linha dos recordings ${row.id}`}>
                          {columns
                            .filter((column) => column.dataKey !== 'channelName')
                            .map((column) => (
                              <TableCell key={column.dataKey} aria-label={column.description}>
                                <Table.Cell {...{ row, column }} />
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </TableMui>
              </TableContainer>
            </>
          ))}
      </Box>
      <Box className={classes.tablePaginationContainer}>
        <Button
          className={classes.exportPDFButton}
          color="primary"
          variant="contained"
          onClick={handleExportAsPDF}
        >
          {t('_common:export_as_PDF')}
        </Button>
      </Box>
    </>
  );
}

function Loading() {
  const { t } = useTranslation();
  const columns = useColumns({ t, customerId: 0 });
  return <Table.Loading {...{ columns }} />;
}

export default asyncComponent(RecordingsTable, <Loading />);
