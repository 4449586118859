import { Box, Typography, Alert } from '@mui/material';
import {
  selectedDateAtom,
  TIME_PLAYBACK_MINUTES,
  getRecordedDataInDate,
  currentTimeAtom,
  playbackRateAtom,
  playbackStartDateDataBarSelector,
  recordsAtom,
  liveSelector,
  pausedAtom,
  mutedAtom,
  actualFowardRewindSecondsAtom,
  lastFowardRewindClickAtom,
  selectedDateInPickerAtom,
  playbackSourceStartDateAtom,
} from 'atoms/playback';
import { VideoPlayer } from 'components/core';
import { _channelSegment } from 'components/core/VideoPlayer/hlsPlugin';
import { timeMinute, timeSecond, timeMillisecond } from 'd3';
import { useChannelStartLive, useLogger } from 'hooks';
import { useCanPlayChannel, useGetChannelThumbnail } from 'hooks/channels';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SESSION_STORAGE } from 'services/auth';
import { mutate } from 'swr';
import { CustomizedResponseCodeEnum, VideoLiveSource } from 'types/channels.types';
import { ChannelSimple } from 'types/mosaics.types';
import VideoJsPlayer from 'video.js/dist/types/player';

function VideoWithPlayback({
  channel,
  onZoomChange,
  zoom,
  widescreen,
  enableZoom = true,
  mosaicId = null,
  videoLiveSource,
  setVideoLiveSource,
}: {
  channel: ChannelSimple;
  onZoomChange?: (zoom: number) => void;
  zoom?: number;
  enableZoom?: boolean;
  widescreen?: boolean;
  mosaicId?: number | null;
  videoLiveSource?: VideoLiveSource;
  setVideoLiveSource?: (value: VideoLiveSource) => void;
}) {
  // const { enqueueError } = useMessages();
  const { t } = useTranslation();
  const live = useRecoilValue(liveSelector({ mosaicId, channelId: channel.id }));
  const paused = useRecoilValue(pausedAtom(channel.id));
  const muted = useRecoilValue(mutedAtom(channel.id));
  const thumbnail = useGetChannelThumbnail({
    channelId: channel.id,
    channelPrivate: channel.channelPrivate,
  });
  const canPlay = useCanPlayChannel(channel);

  if (!canPlay) {
    return (
      <>
        <Box overflow="hidden" flex="1 1 auto" margin="auto">
          <Alert severity="warning">
            {t('channels:you_cannot_view_images_from_a_private_channel')}
          </Alert>
        </Box>
      </>
    );
  }

  return (
    <>
      {live ? (
        <LiveVideo
          channelId={channel.id}
          channelName={channel.name}
          {...{
            muted,
            paused,
            widescreen,
            onZoomChange,
            zoom,
            enableZoom,
            mosaicId,
            thumbnail,
            videoLiveSource,
            setVideoLiveSource,
          }}
        />
      ) : (
        <PlaybackVideo
          channelId={channel.id}
          {...{
            muted,
            paused,
            widescreen,
            onZoomChange,
            zoom,
            enableZoom,
            mosaicId,
            thumbnail,
          }}
        />
      )}
    </>
  );
}

export function LiveVideo({
  channelId,
  channelName,
  paused,
  muted,
  widescreen,
  onZoomChange,
  zoom,
  enableZoom,
  mosaicId,
  thumbnail,
  videoLiveSource,
  setVideoLiveSource,
}: {
  channelId: number;
  channelName: string;
  paused: boolean;
  muted: boolean;
  widescreen?: boolean;
  onZoomChange?: (zoom: number) => void;
  zoom?: number;
  enableZoom: boolean;
  mosaicId: number | null;
  thumbnail: string | null;
  videoLiveSource?: VideoLiveSource;
  setVideoLiveSource?: (value: VideoLiveSource) => void;
}) {
  const { t } = useTranslation('api_errors_messages');
  const lastFowardRewindClick = useRecoilValue(lastFowardRewindClickAtom(channelId));
  const doLog = useLogger();
  const videoLiveSourceChannel = useChannelStartLive({
    channelId,
    conditionFn: () => !videoLiveSource,
  });
  const videoSource = videoLiveSource || videoLiveSourceChannel;

  const [isSegmentContentEmpty, setIsSegmentContentEmpty] = React.useState(false);

  useEffect(function handleSegmentContent() {
    const intervalId = setInterval(() => {
      const shouldSetNoContent =
        _channelSegment.channelId === channelId &&
        _channelSegment.segmentStatus === CustomizedResponseCodeEnum.NO_CONTENT;
      setIsSegmentContentEmpty(shouldSetNoContent);
    }, 6000);

    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (videoSource?.status === CustomizedResponseCodeEnum.CODEC_NOT_SUPPORTED) {
    // Vai cair no ErrorBoundaryVideo
    throw new Error(t('api_errors_messages:codec_not_supported'));
  }

  if (videoSource?.status === CustomizedResponseCodeEnum.CONNECTION_ERROR) {
    // Vai cair no ErrorBoundaryVideo
    throw new Error(t('api_errors_messages:unable_to_get_live_video'));
  }

  if (isSegmentContentEmpty) {
    throw new Error(t('api_errors_messages:device_is_not_responding'));
  }

  const handleRewindFoward = useRecoilCallback(
    ({ snapshot, set }) => async () => {
      const time = await snapshot.getPromise(actualFowardRewindSecondsAtom);
      const records = await snapshot.getPromise(recordsAtom(channelId));
      if (!time) {
        return;
      }
      // live
      const dateCalc = timeSecond.offset(new Date(), time);
      const recordSelected =
        records?.find((record) => record.startDate <= dateCalc && record.endDate > dateCalc) ||
        null;
      if (!recordSelected) {
        const recordedData = await getRecordedDataInDate(channelId, dateCalc);
        set(recordsAtom(channelId), (r) => (r ? r.concat(recordedData.data) : recordedData.data));
      }
      set(selectedDateAtom({ mosaicId, channelId }), dateCalc);
      set(selectedDateInPickerAtom, dateCalc);
    },
    [channelId]
  );

  useUpdateEffect(
    function doRewindFoward() {
      if (lastFowardRewindClick) {
        handleRewindFoward();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastFowardRewindClick]
  );

  const onErrorPlaylist = useCallback(() => {
    mutate(`/v1/channels/${channelId}/url`, undefined, true);
  }, [channelId]);

  const url = videoSource?.url;

  const sources = useMemo(
    () =>
      url
        ? [
            {
              src: url,
            },
          ]
        : [],
    [url]
  );

  if (!url) {
    return null;
  }

  return (
    <Box overflow="hidden" flex="1 1 auto" role="region" aria-label="live video">
      <VideoPlayer
        {...{
          paused,
          muted,
          widescreen,
          sources,
          onZoomChange,
          zoom,
          enableZoom,
          onErrorPlaylist,
          thumbnail,
          channelId,
          channelName,
        }}
        key={`channel-${channelId}`}
        id={`channel-${channelId}`}
        live
        autoplay
        fill
        noControlBar
        width={320}
        height={320}
        preload="auto"
        authorization={`Bearer ${videoSource?.token}`}
        doLog={(message, level) =>
          typeof message === 'object'
            ? doLog({ ...message, channelId }, level)
            : doLog({ error: message, channelId }, level)
        }
      />
    </Box>
  );
}

function PlaybackVideo({
  channelId,
  paused,
  muted,
  widescreen,
  onZoomChange,
  zoom,
  enableZoom,
  mosaicId,
  thumbnail,
}: {
  channelId: number;
  paused: boolean;
  muted: boolean;
  widescreen?: boolean;
  onZoomChange?: (zoom: number) => void;
  zoom?: number;
  enableZoom: boolean;
  mosaicId: number | null;
  thumbnail: string | null;
}) {
  const { t } = useTranslation('video');
  const playbackStartDateDataBar = useRecoilValue(
    playbackStartDateDataBarSelector({ mosaicId, channelId })
  );
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const setCurrentTime = useSetRecoilState(currentTimeAtom(channelId));
  const playbackRate = useRecoilValue(playbackRateAtom(channelId));
  const lastFowardRewindClick = useRecoilValue(lastFowardRewindClickAtom(channelId));
  // alias as Date pois este componente sempre deverá ter data selecionada
  const selectedDate = useRecoilValue(selectedDateAtom({ mosaicId, channelId }));
  const [sourceStartDate, setSourceStartDate] = useRecoilState(
    playbackSourceStartDateAtom(channelId)
  );

  const setSourceDatesWhenSelectADate = useCallback(() => {
    if (player && playbackStartDateDataBar && selectedDate) {
      const playbackStartDateDataBarAsDate = new Date(playbackStartDateDataBar);
      const endDate = timeSecond.offset(playbackStartDateDataBarAsDate, player.duration());
      const isActualSource =
        selectedDate < endDate && selectedDate > playbackStartDateDataBarAsDate;

      player.currentTime(timeSecond.count(playbackStartDateDataBarAsDate, selectedDate));
      if (isActualSource) {
        return;
      }
    }
    setSourceStartDate(selectedDate ? selectedDate.toISOString() : null);
  }, [playbackStartDateDataBar, player, selectedDate, setSourceStartDate]);

  useEffect(
    function setSourceDatesWhenSelectADateEffect() {
      setSourceDatesWhenSelectADate();
    },
    [selectedDate, channelId, player, setSourceDatesWhenSelectADate]
  );

  const sources = useMemo(
    () => [
      {
        src: sourceStartDate
          ? `${
              process.env.REACT_APP_BASE_URL
            }/v1/channels/${channelId}/playback/playlist.m3u8?startDate=${sourceStartDate}&endDate=${timeMinute
              .offset(new Date(sourceStartDate), TIME_PLAYBACK_MINUTES)
              .toISOString()}`
          : '',
      },
    ],
    [sourceStartDate, channelId]
  );

  useEffect(
    function updateCurrentTime() {
      const interval = setInterval(() => {
        if (!player) {
          return;
        }
        if (!player.ended()) {
          setCurrentTime(Number(player.currentTime()));
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    },
    [player, setCurrentTime]
  );

  useEffect(
    function changeRate() {
      if (player) {
        player.playbackRate(playbackRate);
      }
    },
    [player, playbackRate]
  );

  useEffect(
    function cleanPlayerWhenNoRecord() {
      if (!playbackStartDateDataBar) {
        setPlayer && setPlayer(null);
      }
    },
    [playbackStartDateDataBar, setPlayer]
  );

  const handleEnded = useRecoilCallback(
    ({ set }) => () => {
      if (!player) {
        return;
      }
      if (!playbackStartDateDataBar) {
        return;
      }
      const endDateSrc = timeSecond.offset(new Date(playbackStartDateDataBar), player.duration());
      (async function fetchRecords() {
        const recordedData = await getRecordedDataInDate(
          channelId,
          timeSecond.offset(endDateSrc, 1)
        );

        if (recordedData.data && recordedData.data.length) {
          set(
            selectedDateAtom({ mosaicId, channelId }),
            timeMillisecond.offset(recordedData.data[0].startDate, 10)
          );
          set(recordsAtom(channelId), (records) =>
            records ? records.concat(recordedData.data) : recordedData.data
          );
        }
      })();
    },
    [player, playbackStartDateDataBar, channelId]
  );

  useEffect(
    function setEndedEvent() {
      if (player) {
        player.off('ended');
        player.on('ended', handleEnded);
      }
    },
    [player, handleEnded]
  );

  const handleRewindFoward = useRecoilCallback(
    ({ snapshot, set }) => async () => {
      if (!selectedDate) {
        return;
      }
      const timeToPass = await snapshot.getPromise(actualFowardRewindSecondsAtom);
      const records = await snapshot.getPromise(recordsAtom(channelId));
      if (!timeToPass) {
        return;
      }
      let newCurrentTime = 0;
      if (player) {
        // Há vídeo playback rodando
        newCurrentTime = Number(player.currentTime()) + timeToPass;
        if (newCurrentTime > 0 && newCurrentTime < Number(player.duration())) {
          player.currentTime(newCurrentTime);
          return;
        }
      }
      let dateCalc = timeSecond.offset(selectedDate, timeToPass);
      if (playbackStartDateDataBar) {
        // Há vídeo playback rodando
        dateCalc = timeSecond.offset(new Date(playbackStartDateDataBar), newCurrentTime);
      }
      const recordSelected =
        records?.find((record) => record.startDate <= dateCalc && record.endDate > dateCalc) ||
        null;

      if (!recordSelected) {
        const recordedData = await getRecordedDataInDate(channelId, dateCalc);
        set(recordsAtom(channelId), (r) => (r ? r.concat(recordedData.data) : recordedData.data));
      }
      set(selectedDateAtom({ mosaicId, channelId }), dateCalc);
      set(selectedDateInPickerAtom, dateCalc);
    },
    [player, playbackStartDateDataBar, selectedDate, channelId]
  );

  useUpdateEffect(
    function doRewindFoward() {
      if (lastFowardRewindClick) {
        handleRewindFoward();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastFowardRewindClick]
  );

  return (
    <>
      {playbackStartDateDataBar ? (
        <Box overflow="hidden" flex="1 1 auto" role="region" aria-label="playback video">
          <VideoPlayer
            {...{
              paused,
              muted,
              widescreen,
              sources,
              onZoomChange,
              zoom,
              enableZoom,
              thumbnail,
              channelId,
            }}
            key={`channel-${channelId}-playback`}
            id={`channel-${channelId}-playback`}
            authorization={`Bearer ${sessionStorage.getItem(SESSION_STORAGE.TOKEN)}`}
            setVideoPlayer={setPlayer}
            autoplay
            fill
            noControlBar
            width={320}
            height={320}
            preload="auto"
          />
        </Box>
      ) : (
        <Box flex="1 1 auto" alignItems="center" justifyContent="center" display="flex">
          <Typography variant="h6" color="textSecondary">
            {t('video:there_is_no_video_recorded_at_the_selected_time')}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default VideoWithPlayback;
